import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from '../common/container'
import { ProjectLink } from './style'
import { Subtitle } from '../common/text'

function FeaturedProjects({ projects }) {
  return (
    <Container variant="primary">
      <div className="container">
        <Subtitle>Proyectos Destacados</Subtitle>
        <div className="row">
          {projects.map(({
            id, title, slug, thumbnail,
          }) => (
            <ProjectLink
              key={id}
              className="col-xs-12 col-md-4"
              to={`/projects${slug}`}
            >
              <GatsbyImage
                image={thumbnail.gatsbyImageData}
                alt={thumbnail.description}
                style={{ borderRadius: '50%', marginBottom: '30px' }}
              />
              <h3>{title}</h3>
            </ProjectLink>
          ))}
        </div>
      </div>
    </Container>
  )
}

FeaturedProjects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        description: PropTypes.string,
        gatsbyImageData: PropTypes.object,
      }).isRequired,
    }),
  ),
}

FeaturedProjects.defaultProps = {
  projects: [],
}

export default FeaturedProjects
