import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Container from '../common/container'
import { ImageSection, Section, ImageWithCaption } from './style'

const renderChild = (child) => {
  if (child.type === Img && child.props.fluid) {
    return (
      <ImageSection className="col-xs-12 col-sm" image={child.props.fluid}>
        {child}
      </ImageSection>
    )
  }

  if (child.props.image) {
    return (
      <ImageSection className="col-xs-12 col-sm" image={child.props.image}>
        {child}
      </ImageSection>
    )
  }

  return <Section className="col-xs-12 col-sm">{child}</Section>
}

function ImageText({ children, className, variant }) {
  return (
    <Container className={className} variant={variant}>
      <div className="container">
        <div className="row">{React.Children.map(children, renderChild)}</div>
      </div>
    </Container>
  )
}

ImageText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
}

ImageText.defaultProps = { className: undefined, variant: undefined }

ImageText.WithCaption = ImageWithCaption

export default ImageText
