import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import Container from '../components/common/container'
import { Cta, CtaContainer } from '../components/common/cta'
import { Title, Description } from '../components/common/text'
import { PrimaryLink } from '../components/common/links'
import ProjectSearch from '../components/projectSearch'
import FeaturedProjects from '../components/featuredProjects'
import ImageText from '../components/imageText'

function ProjectRepositoryPage({ data }) {
  return (
    <Layout>
      <SEO title="Repositorio Virtual" />
      <Container variant="primary">
        <div className="container">
          <Title>{data.projectRepository.frontmatter.title}</Title>
          <Description
            centered
            dangerouslySetInnerHTML={{ __html: data.projectRepository.html }}
          />
          <ProjectSearch
            standalone
            indexName={process.env.GATSBY_ALGOLIA_PROJECT_REPOSITORY_INDEX}
            suggestionsIndexName={
              process.env.GATSBY_ALGOLIA_PROJECT_REPOSITORY_SUGGESTIONS_INDEX
            }
            placeholder="Buscar proyecto..."
            searchUrl="/projects/search"
          />
        </div>
      </Container>
      <FeaturedProjects projects={data.featuredProjects.projectList} />
      <Container>
        <div className="container">
          <ImageText>
            <ImageText.WithCaption
              image={
                data.joinRepository.frontmatter.image.childImageSharp.fluid
              }
            >
              <Img
                fluid={
                  data.joinRepository.frontmatter.image.childImageSharp.fluid
                }
                style={{ borderRadius: '50%' }}
              />
              <PrimaryLink
                href={data.joinRepository.frontmatter.buttonLink}
                style={{ alignSelf: 'center' }}
              >
                {data.joinRepository.frontmatter.buttonText}
              </PrimaryLink>
            </ImageText.WithCaption>

            <article>
              <h1>{data.joinRepository.frontmatter.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.joinRepository.html,
                }}
              />
            </article>
          </ImageText>

          <CtaContainer>
            <Cta href="/projects/search">Visita nuestros Proyectos</Cta>
          </CtaContainer>
        </div>
      </Container>
    </Layout>
  )
}

// TODO: Make this more detailed
ProjectRepositoryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ProjectRepositoryDataQuery {
    projectRepository: markdownRemark(
      fileAbsolutePath: { regex: "//content/projectRepository.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }

    featuredProjects: contentfulContentFeaturedProjects(
      name: { eq: "Project repository list" }
    ) {
      projectList {
        id
        title
        slug
        thumbnail {
          description
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
    }

    joinRepository: markdownRemark(
      fileAbsolutePath: { regex: "//content/joinRepository.md/" }
    ) {
      frontmatter {
        title
        buttonLink
        buttonText
        image {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      html
    }
  }
`

export default ProjectRepositoryPage
