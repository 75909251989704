import styled from '@emotion/styled'
import { mediaQuery } from '../../styles/base'

const MAX_IMAGE_SIZE = 450

export const ImageSection = styled.div`
  ${mediaQuery({
    margin: ['0 auto 30px', 'auto'],
  })}
  max-width: ${({ image }) => (image.presentationWidth
    ? `${Math.min(image.presentationWidth, MAX_IMAGE_SIZE)}px`
    : 'fit-content')};
`

export const Section = styled.div`
  display: flex;
  align-items: center;
`

export const ImageWithCaption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 15px;
`
