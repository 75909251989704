import React from 'react'
import styled from '@emotion/styled'
import { PrimaryLink } from './links'
import { PrimaryButton } from './buttons'

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`

export function Cta({ children, href, ...props }) {
  const RootElement = href ? PrimaryLink : PrimaryButton

  return (
    <RootElement padding="large" href={href} {...props}>
      {children}
    </RootElement>
  )
}
